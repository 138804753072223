import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import $ from "jquery"

import t from "../locale"
import FormPopup from "./formPopup"

const ContactForm = props => {
  const [send, setSend] = useState(false)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [acceptance, setAcceptance] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const renderPopup = () => {
    if (showPopup) {
      return <FormPopup handleClose={closePopup} lang={props.lang} />
    }
  }

  useEffect(() => {
    $("form .input-wrapper input, form .input-wrapper textarea").focusin(
      function () {
        $(this).parent().addClass("input-wrapper--filled")
      }
    )
    $("form .input-wrapper input, form .input-wrapper textarea").focusout(
      function () {
        var inputVal = $(this).val().length
        if (inputVal > 0) {
          $(this).parent().addClass("input-wrapper--filled")
        } else {
          $(this).parent().removeClass("input-wrapper--filled")
        }
      }
    )
  })

  const resetForm = () => {
    setSend(false)
    setFullName("")
    setEmail("")
    setMessage("")
    setAcceptance(false)
  }

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("fullName", fullName)
    formData.set("email", email)
    formData.set("message", message)
    formData.set("acceptance", acceptance)

    axios({
      method: "post",
      url:
        "https://wp.hogra.com/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        console.log("Submit success")
        resetForm()
        openPopup()
      })
      .catch(error => {
        console.log("Submit error:", error)
      })
  }

  return (
    <>
      <form onSubmit={formSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="input-wrapper">
              <label>{t("Full name*", props.lang)}</label>
              <input
                type="text"
                name="fullname"
                id="fullname"
                value={fullName}
                onChange={e => setFullName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="input-wrapper">
              <label>{t("E-mail*", props.lang)}</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="input-wrapper">
              <label>{t("Message*", props.lang)}</label>
              <textarea
                name="message"
                id="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="acceptance" className="checkbox-wrapper">
              <input
                type="checkbox"
                id="acceptance"
                checked={acceptance}
                onChange={e => setAcceptance(!acceptance)}
                required
              />
              <span>
                {t(
                  "*I agree to the processing of my personal data by Hogra, Frutroed 14, FO 100 Tórshavn, Faroe Islands, TIN: FO 572810 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our",
                  props.lang
                )}{" "}
                <Link to={t("/privacy-policy", props.lang)} target="_blank">
                  {t("Privacy Policy", props.lang)}
                </Link>
                .
              </span>
            </label>
          </div>
          <div className="col-12">
            <div className="center-button">
              <button
                type="submit"
                disabled={!acceptance}
                className="btn btn--filled btn--send"
              >
                {send === true
                  ? t("Sending...", props.lang)
                  : t("Submit", props.lang)}
              </button>
            </div>
          </div>
        </div>
      </form>
      {renderPopup()}
    </>
  )
}

export default ContactForm
