import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SubpageHeader from "../components/subpageHeader"
import ContactForm from "../components/contactForm"
import { StaticQuery, graphql } from "gatsby"

const ContactPage = ({ pageContent }) => {
  const lang = "fo"
  return (
    <Layout lang={lang}>
      <SEO
        title={
          pageContent.seo.title ? pageContent.seo.title : pageContent.title
        }
        description={pageContent.seo.metaDesc && pageContent.seo.metaDesc}
        image={
          pageContent.seo.opengraphImage &&
          pageContent.seo.opengraphImage.localFile.publicURL
        }
      />
      <SubpageHeader title="Samband" />
      <section className="contact-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-2 col-md-3 col-sm-4">
              <div className="single-item">
                <p
                  className="single-item__title"
                  dangerouslySetInnerHTML={{
                    __html: pageContent.contactPage.firstAddressTitle,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageContent.contactPage.faroeIslandContact,
                  }}
                />
              </div>
            </div>
            {/* <div className="col-xl-2 col-md-3 col-sm-4">
              <div className="single-item m-b-5">
                <p
                  className="single-item__title"
                  dangerouslySetInnerHTML={{
                    __html: pageContent.contactPage.secondAddressTitle,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageContent.contactPage.polandContact,
                  }}
                />
              </div>
            </div> */}
            <div className="col-12">
              <p className="single-item__title m-b-15 m-t-50">Samband</p>
            </div>
            <div className="col-xl-2 col-md-3 col-sm-4">
              <div className="single-item">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageContent.contactPage.firstPersonContact,
                  }}
                />
              </div>
            </div>
            {/* <div className="col-xl-2 col-md-3 col-sm-4">
              <div className="single-item m-b-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageContent.contactPage.secondPersonContact,
                  }}
                />
              </div>
            </div> */}
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-12">
              <div className="form-container">
                <p className="form-container__title">Send okkum eini boð</p>
                <ContactForm lang={lang} />
              </div>
            </div>
          </div>
        </div>
        <div className="company-info">
          <p className="company-info__title">Virkisupplýsingar</p>
          <div className="inner">
            <div
              dangerouslySetInnerHTML={{
                __html: pageContent.contactPage.companyInformationLeft,
              }}
            />
          </div>
          {/* <div className="inner">
            <div
              dangerouslySetInnerHTML={{
                __html: pageContent.contactPage.companyInformationRight,
              }}
            />
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        pageContent: wpPage(id: { eq: "cG9zdDo2NTg=" }) {
          seo {
            title
            metaDesc
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
          contactPage {
            companyInformationLeft
            companyInformationRight
            faroeIslandContact
            fieldGroupName
            firstPersonContact
            polandContact
            secondPersonContact
            firstAddressTitle
            secondAddressTitle
          }
        }
      }
    `}
    render={data => <ContactPage {...data} />}
  />
)
