import React from "react"

import t from "../locale"

const FormPopup = props => {
  return (
    <>
      <div className="popup">
        <p className="popup__title">{t("Takk fyri!", props.lang)}</p>
        <p
          className="popup__desc"
          dangerouslySetInnerHTML={{
            __html: t("Vit svara tær so skjótt, vit kunnu.", props.lang),
          }}
        />
        <img
          src={require("../assets/images/ico-thanks.svg")}
          alt=""
          className="popup__icon"
        />
        <button
          type="button"
          className="btn btn--filled"
          onClick={props.handleClose}
        >
          {t("Back", props.lang)}
        </button>
      </div>
      <div
        className="popup-overlay"
        onClick={props.handleClose}
        tabIndex={-1}
        role="presentation"
      ></div>
    </>
  )
}

export default FormPopup
